import { Anchor, AppShell, Center, Group, Header, Space } from "@mantine/core";

export function Scaffold(props: any) {

  return (
    <>     
      <Center>
        {props.children}
      </Center>
    </>
  );
}