import logo from "../assets/logo_small.png";

interface LogoProps {
  height?: string | number | undefined,
  width?: string | number | undefined,
  borderRadius?: string | number | undefined
}

export function Logo(props: LogoProps) {
  const { width = "50", height = "50", borderRadius = "10%" } = props ?? {};
  return (
    <img src={logo} height={height} width={width} style={{ borderRadius }} alt="Logo"/>
  );
}