import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import App from "./App";
import Path from "./constants/path";
import HomeView from "./views/homeView";
import { createRoot } from 'react-dom/client';
import './index.css';
import { MantineProvider, MantineThemeOverride } from "@mantine/core";

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript


const theme: MantineThemeOverride = { colorScheme: 'dark' };

root.render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path={Path.home} element={<App />}>
            <Route path={Path.home} element={<HomeView />} />
            <Route path='*' element={<Navigate to={Path.home} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </MantineProvider>
  </React.StrictMode>
);