/* eslint-disable no-restricted-globals */

import { Title, Text, Stack, Center, TextInput, Button, CopyButton } from "@mantine/core";
import { Logo } from "../components/logo";
import { useParams } from "react-router-dom";
import { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

const instructions = "Please copy this code then return to the app.";

export default function HomeView() {
  const urlParams = new URLSearchParams(location.search);

  const code = urlParams.get('code');
  const state = urlParams.get('state');

  const isValid = code && state;

  const copyData = JSON.stringify({ c: code, s: state });

  const [didCopy, setDidCopy] = useState(false);

  const onCopy = () => {
    setDidCopy(true);
    setTimeout(() => setDidCopy(false), 1000);
  };

  return (
    <Center>
      <Stack>
        <Center>
          <Logo height={150} width={150} />
        </Center>
        {!isValid &&
          <Center>
            <Title className="text" order={2}>Hmm... There's nothing here</Title>
          </Center>
        }
        {isValid && <Stack>
          <Center>
            <Title className="text" order={2} >One more step</Title>
          </Center>
          <Center>
            <div style={{ maxWidth: "500px" }}>
              <Text className="text" >{instructions}</Text>
            </div>
          </Center>
          <TextInput value={copyData} disabled />
          <CopyToClipboard text={copyData} onCopy={onCopy}>
            <Button fullWidth color={didCopy ? "teal" : undefined} >
              {didCopy ? 'Copied' : "Copy code"}
            </Button>
          </CopyToClipboard>
        </Stack>}
      </Stack>
    </Center>
  );
}